import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "common-actions-are-frequently-used-actions-that-appear-multiple-times-across-different-components-and-workflows-for-platform-consistency-these-actions-should-only-be-applied-in-the-ways-described-below"
    }}>{`Common actions are frequently used actions that appear multiple times across different components and workflows. For platform consistency, these actions should only be applied in the ways described below.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Regressive actions</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Progressive actions</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "regressive-actions"
    }}>{`Regressive actions`}</h2>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Cancel</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Clear</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Close</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Delete</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Remove</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Reset</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "cancel"
    }}>{`Cancel`}</h3>
    <p>{`Cancel stops the current action and closes the component or item. The user should be warned of any possible negative consequences of stopping an action from progressing, such as data corruption or data loss.`}</p>
    <p><strong parentName="p">{`Usage:`}</strong>{` Use a `}<em parentName="p">{`secondary button`}</em>{` or a `}<em parentName="p">{`link`}</em>{`.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "55.434782608695656%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of cancel in a modal",
        "title": "Example of cancel in a modal",
        "src": "/static/e659501d11d5d164f344abad03898984/fb070/common-action-1.png",
        "srcSet": ["/static/e659501d11d5d164f344abad03898984/d6747/common-action-1.png 288w", "/static/e659501d11d5d164f344abad03898984/09548/common-action-1.png 576w", "/static/e659501d11d5d164f344abad03898984/fb070/common-action-1.png 1152w", "/static/e659501d11d5d164f344abad03898984/c3e47/common-action-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "clear"
    }}>{`Clear`}</h3>
    <p>{`This action clears data from fields or removes selections. `}<em parentName="p">{`Clear`}</em>{` can also delete the contents of a document, such as a log. Typically, the default selection or value is reset for controls that have a default selection or value, such as radio buttons.`}</p>
    <p><strong parentName="p">{`Usage:`}</strong>{` Use the `}<inlineCode parentName="p">{`x`}</inlineCode>{` icon on the right side of a field, item, or value.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "58.016304347826086%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of clear in Multi-select Dropdown and in Search",
        "title": "Example of clear in Multi-select Dropdown and in Search",
        "src": "/static/35da3f4e228bb845ab0b1bce680f573b/fb070/common-action-2.png",
        "srcSet": ["/static/35da3f4e228bb845ab0b1bce680f573b/d6747/common-action-2.png 288w", "/static/35da3f4e228bb845ab0b1bce680f573b/09548/common-action-2.png 576w", "/static/35da3f4e228bb845ab0b1bce680f573b/fb070/common-action-2.png 1152w", "/static/35da3f4e228bb845ab0b1bce680f573b/c3e47/common-action-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "close"
    }}>{`Close`}</h3>
    <p>{`This action closes the current page, window, or menu. One example is closing a secondary window containing help. `}<em parentName="p">{`Close`}</em>{` is also used to dismiss information, such as notifications.`}</p>
    <p><strong parentName="p">{`Usage:`}</strong>{` Use the `}<inlineCode parentName="p">{`close`}</inlineCode>{` icon, which is typically placed on the upper right side of the element. Do not use `}<em parentName="p">{`close`}</em>{` in a button.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "39.130434782608695%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of close in an inline notification",
        "title": "Example of close in an inline notification",
        "src": "/static/ad273d54ddd19c09f71cae3bf691a1b8/fb070/common-action-3.png",
        "srcSet": ["/static/ad273d54ddd19c09f71cae3bf691a1b8/d6747/common-action-3.png 288w", "/static/ad273d54ddd19c09f71cae3bf691a1b8/09548/common-action-3.png 576w", "/static/ad273d54ddd19c09f71cae3bf691a1b8/fb070/common-action-3.png 1152w", "/static/ad273d54ddd19c09f71cae3bf691a1b8/c3e47/common-action-3.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "delete"
    }}>{`Delete`}</h3>
    <p>{`This action eradicates an existing object. `}<em parentName="p">{`Delete`}</em>{` actions cannot be easily undone and are typically permanent. The user should be warned of the negative consequences of destroying an object, such as loss of data.`}</p>
    <p><strong parentName="p">{`Usage:`}</strong>{` Use either the `}<inlineCode parentName="p">{`delete`}</inlineCode>{` “trash can” icon, a `}<em parentName="p">{`danger button`}</em>{`, or a danger option in a menu. A `}<em parentName="p">{`danger modal`}</em>{` is used when a warning is needed to confirm an action.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "111.00543478260869%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of delete in a Modal and Overflow Menu",
        "title": "Example of delete in a Modal and Overflow Menu",
        "src": "/static/a480730388da6cd6c3f008e6f65379cd/fb070/common-action-4.png",
        "srcSet": ["/static/a480730388da6cd6c3f008e6f65379cd/d6747/common-action-4.png 288w", "/static/a480730388da6cd6c3f008e6f65379cd/09548/common-action-4.png 576w", "/static/a480730388da6cd6c3f008e6f65379cd/fb070/common-action-4.png 1152w", "/static/a480730388da6cd6c3f008e6f65379cd/c3e47/common-action-4.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "remove"
    }}>{`Remove`}</h3>
    <p>{`This action removes an object from a list or item; however, the object is not destroyed as a result of the action. Multiple objects can be removed at once.`}</p>
    <p><strong parentName="p">{`Usage:`}</strong>{` Use as a `}<em parentName="p">{`button`}</em>{` or `}<inlineCode parentName="p">{`subtract`}</inlineCode>{` icon or glyph.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "47.69021739130435%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of Remove in a Structured List",
        "title": "Example of Remove in a Structured List",
        "src": "/static/4f222603c06c6c170823133b1d8a0297/fb070/common-action-5.png",
        "srcSet": ["/static/4f222603c06c6c170823133b1d8a0297/d6747/common-action-5.png 288w", "/static/4f222603c06c6c170823133b1d8a0297/09548/common-action-5.png 576w", "/static/4f222603c06c6c170823133b1d8a0297/fb070/common-action-5.png 1152w", "/static/4f222603c06c6c170823133b1d8a0297/c3e47/common-action-5.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "reset"
    }}>{`Reset`}</h3>
    <p>{`This action reverts values back to their last saved state. The last saved state includes the values stored the last time the user clicked or triggered “Apply.”`}</p>
    <p><strong parentName="p">{`Usage:`}</strong>{` Typically applied as a `}<em parentName="p">{`link`}</em>{`.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "29.891304347826086%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of reset in a filter",
        "title": "Example of reset in a filter",
        "src": "/static/a96e4cc567d41fa91b0c6837e61fa45e/fb070/common-action-6.png",
        "srcSet": ["/static/a96e4cc567d41fa91b0c6837e61fa45e/d6747/common-action-6.png 288w", "/static/a96e4cc567d41fa91b0c6837e61fa45e/09548/common-action-6.png 576w", "/static/a96e4cc567d41fa91b0c6837e61fa45e/fb070/common-action-6.png 1152w", "/static/a96e4cc567d41fa91b0c6837e61fa45e/c3e47/common-action-6.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "progressive-actions"
    }}>{`Progressive actions`}</h2>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Add</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Copy</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Edit</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Next</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Refresh</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "add"
    }}>{`Add`}</h3>
    <p>{`This action adds an existing object to a list, set, or system. For example, adding a document to a folder.`}</p>
    <p><strong parentName="p">{`Usage:`}</strong>{` Use a `}<em parentName="p">{`primary button`}</em>{`, `}<em parentName="p">{`button with icon`}</em>{`, `}<inlineCode parentName="p">{`add--glyph`}</inlineCode>{` or `}<inlineCode parentName="p">{`add--outline`}</inlineCode>{` icon.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "33.96739130434783%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of add as a Button with Icon in a Data Table",
        "title": "Example of add as a Button with Icon in a Data Table",
        "src": "/static/9b9777055d3d822ac1eff7822555bdbc/fb070/common-action-7.png",
        "srcSet": ["/static/9b9777055d3d822ac1eff7822555bdbc/d6747/common-action-7.png 288w", "/static/9b9777055d3d822ac1eff7822555bdbc/09548/common-action-7.png 576w", "/static/9b9777055d3d822ac1eff7822555bdbc/fb070/common-action-7.png 1152w", "/static/9b9777055d3d822ac1eff7822555bdbc/c3e47/common-action-7.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "copy"
    }}>{`Copy`}</h3>
    <p>{`Creates a new identical instance of the selected object(s) in a specific destination.`}</p>
    <p><strong parentName="p">{`Usage:`}</strong>{` Use the `}<inlineCode parentName="p">{`copy`}</inlineCode>{` icon with the confirmation “copied” tooltip appearing post-click.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "30.298913043478258%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of copy in a Code Snippet",
        "title": "Example of copy in a Code Snippet",
        "src": "/static/dc21f785dc50f8670c2b42fd17f107a4/fb070/common-action-8.png",
        "srcSet": ["/static/dc21f785dc50f8670c2b42fd17f107a4/d6747/common-action-8.png 288w", "/static/dc21f785dc50f8670c2b42fd17f107a4/09548/common-action-8.png 576w", "/static/dc21f785dc50f8670c2b42fd17f107a4/fb070/common-action-8.png 1152w", "/static/dc21f785dc50f8670c2b42fd17f107a4/c3e47/common-action-8.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "edit"
    }}>{`Edit`}</h3>
    <p>{`This action allows data or values to be changed. `}<em parentName="p">{`Edit`}</em>{` commonly triggers a state change to the targeted object or input item.`}</p>
    <p><strong parentName="p">{`Usage:`}</strong>{` Use as an option in a menu, or as a `}<em parentName="p">{`button`}</em>{` or `}<inlineCode parentName="p">{`edit`}</inlineCode>{` icon.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "30.978260869565222%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of edit in a Data Table cell",
        "title": "Example of edit in a Data Table cell",
        "src": "/static/8ff559658c8e10e91c2309fba60eac47/fb070/common-action-9.png",
        "srcSet": ["/static/8ff559658c8e10e91c2309fba60eac47/d6747/common-action-9.png 288w", "/static/8ff559658c8e10e91c2309fba60eac47/09548/common-action-9.png 576w", "/static/8ff559658c8e10e91c2309fba60eac47/fb070/common-action-9.png 1152w", "/static/8ff559658c8e10e91c2309fba60eac47/c3e47/common-action-9.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "42.11956521739131%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of edit in an Overflow Menu",
        "title": "Example of edit in an Overflow Menu",
        "src": "/static/15e45f78d42afead195cf036b619267a/fb070/common-action-10.png",
        "srcSet": ["/static/15e45f78d42afead195cf036b619267a/d6747/common-action-10.png 288w", "/static/15e45f78d42afead195cf036b619267a/09548/common-action-10.png 576w", "/static/15e45f78d42afead195cf036b619267a/fb070/common-action-10.png 1152w", "/static/15e45f78d42afead195cf036b619267a/c3e47/common-action-10.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "next"
    }}>{`Next`}</h3>
    <p>{`Advances the user to the next step in a sequence of steps, such as in a wizard.`}</p>
    <p><strong parentName="p">{`Usage:`}</strong>{` Use a `}<em parentName="p">{`button with icon`}</em>{` or a standalone `}<inlineCode parentName="p">{`forward`}</inlineCode>{` icon.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "43.30357142857143%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of next as a Button with Icon",
        "title": "Example of next as a Button with Icon",
        "src": "/static/4c1cc25505f745ea646de669c83933c1/fb070/common-action-11.png",
        "srcSet": ["/static/4c1cc25505f745ea646de669c83933c1/d6747/common-action-11.png 288w", "/static/4c1cc25505f745ea646de669c83933c1/09548/common-action-11.png 576w", "/static/4c1cc25505f745ea646de669c83933c1/fb070/common-action-11.png 1152w", "/static/4c1cc25505f745ea646de669c83933c1/fb104/common-action-11.png 1728w", "/static/4c1cc25505f745ea646de669c83933c1/8fefe/common-action-11.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "refresh"
    }}>{`Refresh`}</h3>
    <p>{`This action reloads the view of an object, list, or data set when the displayed view has become unsynchronized with the source.`}</p>
    <p><strong parentName="p">{`Usage:`}</strong>{` Use the `}<inlineCode parentName="p">{`refresh`}</inlineCode>{` icon or a `}<em parentName="p">{`button`}</em>{`.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of edit in a Data Table cell",
        "title": "Example of edit in a Data Table cell",
        "src": "/static/769cc82bfbade944fce0b7b1c5d6d513/fb070/common-action-12.png",
        "srcSet": ["/static/769cc82bfbade944fce0b7b1c5d6d513/d6747/common-action-12.png 288w", "/static/769cc82bfbade944fce0b7b1c5d6d513/09548/common-action-12.png 576w", "/static/769cc82bfbade944fce0b7b1c5d6d513/fb070/common-action-12.png 1152w", "/static/769cc82bfbade944fce0b7b1c5d6d513/c3e47/common-action-12.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      